import { FormEvent, useState } from "react";
import { Alert, Button, Col, Form, Input, Row } from "reactstrap";
import { PostApi } from "../../api/postApi";
import { useParams } from "react-router-dom";
import RichTextEditor from "../RichTextEditor";
import React from "react";
import { useAppSelector } from "../../features/hooks";
import { selectPostById } from "../../features/slices/postSlice";

type Params = {
    postId: string;
}

const EditPost = () => {
    const { postId } = useParams<Params>();
    const post = useAppSelector(state => selectPostById(state, postId));
    const [successUpdateVisible, setSuccessUpdateVisible] = useState(false);
    const [failedUpdateVisible, setFailedUpdateVisible] = useState(false);

    const onDismissSuccessUpdateVisible = () => setSuccessUpdateVisible(false);
    const onDismissFailedUpdateVisible = () => setFailedUpdateVisible(false);

    const onSuccessfullUpdate = () => {
        setSuccessUpdateVisible(true);
        setTimeout(() => {
            setSuccessUpdateVisible(false);
        }, 5000);
    }

    const onFailedUpdate = () => {
        setFailedUpdateVisible(true);
        setTimeout(() => {
            setFailedUpdateVisible(false);
        }, 5000);
    }

    const handleUpdatePost = async (event: FormEvent) => {
        event.preventDefault();

        const { posttitle, postlink, postorder } = document.forms[0];
        const post = document.forms[0].getElementsByTagName("textarea")[0].value;

        if (!postId) {
            return;
        }

        await PostApi.updatePost(
            post,
            posttitle.value,
            postlink.value,
            "1",
            postId,
            postorder.value)
            .then(onSuccessfullUpdate)
            .catch(onFailedUpdate);
    }

    return <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Edit Post</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="me-2">
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        Topics
                    </button>
                </div>
                <div className="me-2">
                    <button type="button" className="btn btn-outline-secondary dropdown-toggle">
                        <span data-feather="calendar"></span>
                        Categories
                    </button>
                </div>
                <div className="btn-group me-2">
                    <Button outline>Draft Post</Button>
                    <Button outline form="edit-post-form" color="success" type="submit">Update Post</Button>
                </div>
                <button type="button" className="btn  btn-outline-secondary dropdown-toggle">
                    <span data-feather="calendar"></span>
                    This week
                </button>
            </div>
        </div>
        <Form
            id="edit-post-form"
            onSubmit={handleUpdatePost}>
                <Row>
                <Alert
                    style={{}}
                    fade
                    isOpen={successUpdateVisible}
                    toggle={onDismissSuccessUpdateVisible}>
                    The post successfully updated
                </Alert>
                <Alert
                    color="danger"
                    fade
                    isOpen={failedUpdateVisible}
                    toggle={onDismissFailedUpdateVisible}>
                    The post update failed
                </Alert>
                </Row>
            <Row style={{ marginBottom: '10px' }}>
                <Col>
                    <Input
                        style={{ height: "50px" }}
                        id="title"
                        name="posttitle"
                        type="text"
                        defaultValue={post?.title} />
                </Col>
                <Col>
                    <Input
                        style={{ height: "50px" }}
                        id="linl"
                        name="postlink"
                        type="text"
                        defaultValue={post?.link} />
                </Col>
                <Col>
                    <Input
                        style={{ height: "50px" }}
                        id="order"
                        name="postorder"
                        type="text"
                        defaultValue={post?.order} />
                </Col>
            </Row>
            <RichTextEditor setState={() => { }}
                initialValue={post?.data} />
        </Form>
    </>
}

export default EditPost;