import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return <div className="container footer mt-auto py-3 bg-body-tertiary">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-0 my-0 border-top">
            <p className="col-md-4   pt-4 px-4 text-body-secondary" style={{ fontWeight: '600' }}>&copy; XScrolls 2025. All Rights Reserved.</p>

            <ul className="nav col-md-4  pt-3 px-4  justify-content-end">
                <li className="nav-item m-2"><Link to={'/'} className='footerlink'>Home</Link> </li>
                <li className="nav-item m-2"><Link to={'/about'} className='footerlink'>About</Link></li>
            </ul>
        </footer>
    </div>
}

export default Footer;