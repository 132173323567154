import React from "react";
import Header from "./Header";
import Loading from "./loading/Loading";
import { Col, Container, Row } from "reactstrap";
import '../styles/post.css';

const About = () => {
    return <main className="my-5 py-5 justify-content-center">
        <Header collapsed={false} />
        <Container className="px-0 flex-shrink-0 p-5 justify-content-center">
            <Row className='row-cols-auto justify-content-center'>
                <Col
                    xs={{ size: 10 }}
                    sm={{ size: 10 }}
                    md={{ size: 6 }}
                    xl={{ size: 6 }}>
                    <article>
                        <p><strong>Hello!</strong></p>
                        <p>This website consists of notes of development-related information</p>
                        <p>Feel free to ask questions or make suggestions to my email:</p>
                        <h5>aleh.xscrolls@gmail.com</h5>
                    </article>
                </Col>
            </Row>
        </Container>
    </ main>
}

export default About;